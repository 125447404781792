// Generated by Framer (cec372b)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["VhzM9KEAR", "soRZ0jU1e", "rHvVh5iNS"];

const variantClassNames = {rHvVh5iNS: "framer-v-4e59wf", soRZ0jU1e: "framer-v-6y5zou", VhzM9KEAR: "framer-v-1rxhstp"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {Desktop: "VhzM9KEAR", Phone: "rHvVh5iNS", Tablet: "soRZ0jU1e"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};


function toResponsiveImage_194x2gw(value) {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "VhzM9KEAR", image: uXzl0mkGH = {src: new URL("assets/2048/NtG7cJGfMxx6gNR7PUj0TvAIAc.jpg", import.meta.url).href, srcSet: `${new URL("assets/512/NtG7cJGfMxx6gNR7PUj0TvAIAc.jpg", import.meta.url).href} 512w, ${new URL("assets/1024/NtG7cJGfMxx6gNR7PUj0TvAIAc.jpg", import.meta.url).href} 1024w, ${new URL("assets/2048/NtG7cJGfMxx6gNR7PUj0TvAIAc.jpg", import.meta.url).href} 2048w, ${new URL("assets/4096/NtG7cJGfMxx6gNR7PUj0TvAIAc.jpg", import.meta.url).href} 4096w, ${new URL("assets/NtG7cJGfMxx6gNR7PUj0TvAIAc.jpg", import.meta.url).href} 6720w`}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "VhzM9KEAR", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-JGTSb", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<Image {...restProps} background={{alt: "", fit: "fill", ...toResponsiveImage_194x2gw(uXzl0mkGH)}} className={cx("framer-1rxhstp", className)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"VhzM9KEAR"} ref={ref} style={{borderBottomLeftRadius: 1000, borderBottomRightRadius: 0, borderTopLeftRadius: 1000, borderTopRightRadius: 0, ...style}} transition={transition} {...addPropertyOverrides({rHvVh5iNS: {"data-framer-name": "Phone"}, soRZ0jU1e: {"data-framer-name": "Tablet"}}, baseVariant, gestureVariant)}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-JGTSb [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-JGTSb * { box-sizing: border-box; }", ".framer-JGTSb .framer-1d47awr { display: block; }", ".framer-JGTSb .framer-1rxhstp { height: 1112px; overflow: hidden; position: relative; width: 752px; will-change: transform; }", ".framer-JGTSb.framer-v-6y5zou .framer-1rxhstp { height: 547px; width: 640px; }", ".framer-JGTSb.framer-v-4e59wf .framer-1rxhstp { height: 421px; width: 350px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1112
 * @framerIntrinsicWidth 752
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"soRZ0jU1e":{"layout":["fixed","fixed"]},"rHvVh5iNS":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"uXzl0mkGH":"image"}
 */
const FramerGFarUPK3T: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerGFarUPK3T;

FramerGFarUPK3T.displayName = "Switch Image";

FramerGFarUPK3T.defaultProps = {height: 1112, width: 752};

addPropertyControls(FramerGFarUPK3T, {variant: {options: ["VhzM9KEAR", "soRZ0jU1e", "rHvVh5iNS"], optionTitles: ["Desktop", "Tablet", "Phone"], title: "Variant", type: ControlType.Enum}, uXzl0mkGH: {__defaultAssetReference: "data:framer/asset-reference,NtG7cJGfMxx6gNR7PUj0TvAIAc.jpg?originalFilename=microsoft-365-FHhbHW4vFxc-unsplash.jpg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerGFarUPK3T, [])